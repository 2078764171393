<template>
  <div class="anchorActiveBox" :class="showPopFlag > 0?'hideBodyScroll':''">
    <header class="headerBg">
      <div class="topAnchorBox">
        <div class="rightTopBtnBox">
          <span class="rulesBtn" @click="showPop(1)">{{ $t("anchorActive.rules") }} </span>
          <span class="rulesBtn" @click="showPop(2)">{{ $t("anchorActive.rewards") }}</span>
        </div>
        <div class="profileBox" v-if="rankListData.length != 0">
          <div class="swiperBox">
            <div class="swiper-container" id="swiperIndex">
              <div class="swiper-wrapper"  >
                <template v-for="(item, i) in rankListData" >
                    <template v-if="i<3">
                      <img class="swiper-slide" :key="i" v-if="item.headIcon" :src="item.headIcon" alt="">
                      <img class="swiper-slide" :key="i" v-else src="../../assets/anchorActive/defaultAvatar.svg" alt="">
                    </template>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="profileBox" v-if="rankListData.length == 0" style="padding-top: 18px;">
          <img src="../../assets/anchorActive/defaultAvatar.svg" alt="">
        </div>
        <div class="topAnchorBtn">
          <span class="left"></span>
          <span class="topIcon" :class="'top' + rankTop"></span>
          <span class="right"></span>
        </div>
      </div>
      <p class="newStar"></p>
      <p class="subTitle" :class="$i18n.locale == 'es' || $i18n.locale == 'pt' ? 'subTitle2' : ''"></p>
    </header>
    <section class="contentBox">
      <div class="timeBox">
        <div>
          <div class="timeNumBox">
            <span>{{ days[0] }}</span>
            <span>{{ days[1] }}</span>
          </div>
          <p>{{ $t("anchorActive.days") }}</p>
        </div>
        <div>
          <div class="timeNumBox">
          <span>{{ hours[0] }}</span>
          <span>{{ hours[1] }}</span>
          </div>
          <p>{{ $t("anchorActive.hours") }}</p>
        </div>
        <div>
          <div class="timeNumBox">
          <span>{{ minutes[0] }}</span>
          <span>{{ minutes[1] }}</span>
          </div>
          <p>{{ $t("anchorActive.minutes") }}</p>
        </div>
        <div>
          <div class="timeNumBox">
          <span>{{ seconds[0] }}</span>
          <span>{{ seconds[1] }}</span>
          </div>
          <p>{{ $t("anchorActive.seconds") }}</p>
        </div>
      </div>
      <div class="topListBox" >
        <span class="lastWeekBtn" @click="changeRound">{{ round == 1 ? $t("anchorActive.laseWeek") : $t("anchorActive.back") }}</span>
        <header :class="round == 1 ? '' : 'laseWeekBg'">{{ round == 1 ? $t("anchorActive.rank") : $t("anchorActive.laseWeek") }}</header>
        <div class="listBtnBg">
          <span :class="activeListFlag == 1 ? 'activeListBtn' : ''" @click="changeActiveListFlag(1)"> {{ $t("anchorActive.activeList") }} </span>
          <span :class="activeListFlag == 2 ? 'activeListBtn' : ''" @click="changeActiveListFlag(2)"> {{ $t("anchorActive.charmingList") }} </span>
        </div>
        <div class="hideScroll">
          <template v-if="rankListData.length">
            <ul class="listBox activityList" v-if="activeListFlag == 1" @scroll="pageScroll">
              <li v-for="(item,index) in rankListData">
                <div class="listInfoLeft">
                  <span class="userRank">{{ item.top | zeroFillTop }}</span>
                  <img @click="skipUserInfo(item)" v-if="item.headIcon" :src="item.headIcon" alt="">
                  <img @click="skipUserInfo(item)" v-else  src="../../assets/anchorActive/defaultAvatar.svg" alt="">
                  <span class="userName showEllipsis"> {{ item.nickname }} </span>
                </div>
                <div class="listInfoRight">
                  <span>
                    {{ item.score }}
                  </span>
                  
                </div>
              </li>
            </ul>
            <ul class="listBox giftList" v-if="activeListFlag == 2" @scroll="pageScroll">
              <li v-for="(item,index) in rankListData">
                <div class="listInfoLeft">
                  <span class="userRank">{{ item.top | zeroFillTop }}</span>
                  <img @click="skipUserInfo(item)" v-if="item.headIcon" :src="item.headIcon" alt="">
                  <img @click="skipUserInfo(item)" v-else  src="../../assets/anchorActive/defaultAvatar.svg" alt="">
                  <span class="userName showEllipsis"> {{ item.nickname }} </span>
                </div>
                <div class="listInfoRight">
                  <span > {{ item.score }}</span>
                </div>
              </li>
            </ul>
          </template>
          <p v-if="!rankListData.length && !loadingFlag" class="noRankingBox">{{ $t("anchorActive.noRanking") }}</p>
          <div class="loadingBox" v-if="loadingFlag">
            <div>
              <img src="../../assets/anchorActive/loading.png" alt="">
            </div>
          </div>
        </div>
        
      <div class="myRankInfo">
        <div class="listInfoLeft">
          <img @click="skipUserInfo(selfRankInfo)" v-if="selfRankInfo.headIcon" :src="selfRankInfo.headIcon" alt="">
          <img @click="skipUserInfo(selfRankInfo)" v-else src="../../assets/anchorActive/defaultAvatar.svg" alt="">
          <p class="userName showEllipsis"> 
            <span>{{ selfRankInfo.nickname }}</span>  
            <span>{{ $t("anchorActive.rank") }} {{ selfRankInfo.top | topFilter }}</span>  
          </p>
        </div>
        <div class="listInfoRight" :class="activeListFlag == 2 ? 'hartIcon' : ''">
          <span v-if="activeListFlag == 1 "> {{  selfRankInfo.score == 0 ? '--' : selfRankInfo.score  }}</span>
          <span v-else>{{ selfRankInfo.score == 0 ? '--' : selfRankInfo.score }}</span>
        </div>
      </div>
      </div>
    </section>
    <!-- 弹窗 -->
    <div class="popsBox" v-if="showPopFlag > 0">
       <div class="rulesPopBox" v-if="showPopFlag == 1">
          <header>Rules</header>
          <span class="popClose" @click="showPop(0)"></span>
          <ul>
            <li class="rulesItem">
              <header>{{ $t("anchorActive.listOne") }}</header>
              <p>{{ $t("anchorActive.listOneMsg1") }}</p>
              <p>{{ $t("anchorActive.listOneMsg2") }}</p>
            </li>
            <li class="rulesItem">
              <header>{{ $t("anchorActive.listTwo") }}</header>
              <p>{{ $t("anchorActive.listTwoMsg1") }}  </p>
              <p>{{ $t("anchorActive.listTwoMsg2") }} </p>
              <p>{{ $t("anchorActive.listTwoMsg3") }} </p>
            </li>
          </ul>
       </div>
       <div class="rulesPopBox rewrodsPopBox" :class="popTab == 2 ? 'longBg' : ''" v-if="showPopFlag == 2">
          <header>{{ $t("anchorActive.rewards") }} </header>
          <span class="popClose" @click="showPop(0)"></span>
          <div class="listBtnBg">
            <span :class="popTab == 1 ? 'activeListBtn' : ''" @click="changePopTab(1)"> {{ $t("anchorActive.activeList") }} </span>
            <span :class="popTab == 2 ? 'activeListBtn' : ''" @click="changePopTab(2)"> {{ $t("anchorActive.charmingList") }}  </span>
          </div>
          <ul v-if="popTab == 1">
            <li class="rewordsItem">
              <div>{{ $t("anchorActive.top1") }}</div>
              <div>
                <p>{{ $t("anchorActive.points100000") }}</p>
                <p>{{ $t("anchorActive.frame10") }}</p>
                <p>{{ $t("anchorActive.entrance10") }}</p>
              </div>
            </li>
            <li class="rewordsItem">
              <div>{{ $t("anchorActive.top2") }}</div>
              <div>
                <p>{{ $t("anchorActive.points50000") }}</p>
                <p>{{ $t("anchorActive.frame7") }}</p>
                <p>{{ $t("anchorActive.entrance7") }}</p>
              </div>
            </li>
            <li class="rewordsItem">
              <div>{{ $t("anchorActive.top3") }}</div>
              <div class="rewordsTop3">
                <p>{{ $t("anchorActive.frame5") }}</p>
                <p>{{ $t("anchorActive.entrance5") }}</p>
              </div>
            </li>
          </ul>
          <ul v-if="popTab == 2">
            <li class="rewordsItem rewordsGiftItem">
              <div>{{ $t("anchorActive.top1") }}</div>
              <div>
                <p>{{ $t("anchorActive.points50k") }}</p>
                <p>{{ $t("anchorActive.frame10") }}</p>
                <p>{{ $t("anchorActive.entrance10") }}</p>
                <p>{{ $t("anchorActive.banner10") }}</p>
              </div>
            </li>
            <li class="rewordsItem rewordsGiftItem">
              <div>{{ $t("anchorActive.top2") }}</div>
              <div>
                <p>{{ $t("anchorActive.points30k") }}</p>
                <p>{{ $t("anchorActive.frame7") }}</p>
                <p>{{ $t("anchorActive.entrance7") }}</p>
              </div>
            </li>
            <li class="rewordsItem rewordsGiftItem">
              <div>{{ $t("anchorActive.top3") }}</div>
              <div class="rewordsTop3">
                <p>{{ $t("anchorActive.frame5") }}</p>
                <p>{{ $t("anchorActive.entrance5") }}</p>
              </div>
            </li>
          </ul>
       </div>
    </div>
    <van-loading type="spinner" />
  </div>
 
</template>

<script>
import "swiper/css/swiper.css"; //引入swiper样式
import Swiper from "swiper"; //引入swiper
import { Toast,Loading } from "vant";
export default {
  name: "anchorActive",
  data() {
    return {
      // 弹窗展示
      showPopFlag: 0,
      // 弹窗tab切换
      popTab:1,
      // 榜单切换展示
      activeListFlag:1,
      // 时间
      days:[0,0],
      hours:[0,0],
      minutes:[0,0],
      seconds:[0,0], 
      timer:null,
      rankTop:1,
      // 榜单数据
      rankListData:[],

      page: 0,
      size: 10,
      total:0,
      // 1本论 2上一轮
      round: 1,
      // type 1日 2周 3月 4 不限时间
      activeType: 2,
      // 避免重复请求
      notFast: true,
      // 自己的榜单信息
      selfRankInfo:{},
      loadingFlag: true,
      mySwiper: null,
      // 避免重复请求
      sendFlag: true

    };
  },
  watch:{
    rankListData:{
      handler(val){
        this.$nextTick(()=>{
          if(this.rankListData.length > 1){
            this.showSwiper()
          }
        })
      },
      immediate:true
    }
  },
  filters:{
    topFilter(top){
      if(top > 99){
        return '99+';
      }else if(top == 0){
        return '--'
      }else{
        return top
      }
    },
    zeroFillTop(top){
      if(top < 10){
        return '0'+top;
      }else{
        return top;
      }
    }
  },
  created() {
    //调用原生方法
    this.$completes.complete("getUserInfo");
    //原生调用h5方法
    window.getUserInfo = this.getUserInfo;
    window["getUserInfo"] = (data) => {
      this.getUserInfo(data);
    };
    // 判断平台
    this.judgeClient()
    document.title = 'New Star'

    // this.getUserInfo()
  },
  mounted() {
  },
  methods: {
    //分页
    pageScroll(e) {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      // 检查是否滚动到底部
      if (scrollTop + clientHeight >= scrollHeight) {
          if(this.notFast){
              this.page++;
              this.getRankList();
          }
      }
    },
    // 判断平台
    judgeClient() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
        this.client = 'iOS';
      } else if (/(Android)/i.test(navigator.userAgent)) { //判断Android
        this.client = 'Android';
      } else {
        this.client = 'PC';
      }
      return this.client;
    },
    // 跳转资料页  需要uid
    skipUserInfo(item) {
      if (this.client == 'iOS') {
        window["webkit"].messageHandlers['skipUserInfo'].postMessage(JSON.stringify(item.userId));
      } else if (this.client == 'Android') {
        nativeJs["skipUserInfo"](JSON.stringify(item.userId));
      }
    },
    // 渲染swiper
    showSwiper(){
      var that = this;
      this.mySwiper = new Swiper("#swiperIndex", {
        // speed: 500,
        loop: true,
        initialSlide:0,
        allowTouchMove: false, // 禁止手动滑动
        observer:true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents:true,//修改swiper的父元素时，自动初始化swiper
        autoplay: {
          delay: 1000,
          stopOnLastSlide: false,
          disableOnInteraction:false,
        }, //可选选项，自动滑动
        
        on: {
          slideChangeTransitionStart: function(){
            that.rankTop = this.realIndex + 1;
          },
        },
        navigation: {
          nextEl: ".right",
          prevEl: ".left",
        },
        
      });
    },
    // 查看上周榜单
    changeRound(){
      if(this.round == 1){
        this.round = 2;
      }else{
        this.round = 1;
      };
      this.rankListData = [];
      this.getRankList();
    },
    // 名次切换
    reduceRank() {
      // if(this.rankTop == 1){
      //   this.rankTop = 1;
      // }else{
      //   this.rankTop--;
      // }
      // this.mySwiper.activeIndex = this.rankTop
      // this.mySwiper.slideTo(this.mySwiper.activeIndex)
    },
    addRank(){
      if(this.rankTop == 3){
        this.rankTop = 3
      }else{
        this.rankTop++;
      }
      this.mySwiper.slideTo(this.rankTop)
    },
    // 切换榜单展示
    changeActiveListFlag(num){
      this.page = 0;
      this.rankListData = [];
      this.activeListFlag = num;
      this.getRankList();
    },
    // 根据时间戳转换倒计时
    countdown(endTime) {
      this.timer = setInterval( ()=> {
        const now = new Date().getTime();
        const distance = endTime - now;
        
        if (distance < 0) {
          clearInterval(this.timer);
          return;
        }
        
        const days = this.zeroFill(Math.floor(distance / (1000 * 60 * 60 * 24)));
        const hours = this.zeroFill(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        const minutes = this.zeroFill(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
        const seconds = this.zeroFill(Math.floor((distance % (1000 * 60)) / 1000));

        this.days = days;
        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;
      }, 1000);
    },
    zeroFill(num){
      if(num < 10){
        return [0,num]
      }else{
        return [num.toString().slice(0,1),num.toString().slice(1)]
      }
    },
    // 切换弹窗tab
    changePopTab(num){
      this.popTab = num;
    },
    // 切换弹窗展示
    showPop(num){
      this.showPopFlag = num;
      if(num == 0){
        this.changePopTab(1)
      }
    },

    // 获取自己当前排名信息
    getSelfRankInfo(){
      let rankInfo = {
        rankType: this.activeListFlag == 1? 13: 14,
        round: this.round,
        type: this.activeType
      };
      let personLeveldatas = {
        option: "get",
        host:
          this.$serviceIp +
          "/api/activity/carnival/role/rank?token=" +
          this.token +
          "&uid=" +
          this.uid+
          "&rankType=" +
          rankInfo.rankType+
          "&round=" +
          rankInfo.round+
          "&type=" +
          rankInfo.type,
        data: "",
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(personLeveldatas).then((data) => {
        if (data.code == 200) {
         this.selfRankInfo = data.data;
        }else {
          Toast(data.message || this.$t('failed'))
        }
      });
    },
    // 获取榜单
    getRankList(){
      this.loadingFlag = true;
      // 获取自己当前排名
      this.getSelfRankInfo()
      this.notFast = false;
      // rankType 11 12 本活动  13 14是活动二
      let rankInfo = {
        rankType: this.activeListFlag == 1 ? 13 : 14,
        round: this.round,
        type: this.activeType
      };
      let personLeveldatas = {
        option: "get",
        host:
          this.$serviceIp +
          "/api/activity/carnival/role/page?token=" +
          this.token +
          "&uid=" +
          this.uid+
          "&rankType=" +
          rankInfo.rankType+
          "&round=" +
          rankInfo.round+
          "&type=" +
          rankInfo.type+
          "&page=" +
          this.page+
          "&size=" +
          this.size,
        data: "",
        header: JSON.stringify(this.headers),
      };
      if(this.sendFlag){
        this.sendFlag = false;
        this.$server.curlExec(personLeveldatas).then((data) => {
          this.loadingFlag = false;
          if (data.code == 200) {
            this.rankListData.push(...data.data.list);
            //  是否还有下一页
            if(data.data.list.length == data.data.size){
              this.notFast = true
            }
            this.showSwiper()
          }else {
            Toast(data.message || this.$t('failed'))
          }
          this.sendFlag = true;
        });
      }
    },
    getUserInfo(data) {
      this.userInfo = JSON.parse(data);
      this.headers = [
        'Content-Type:' + 'application/json; charset=utf-8',
        'deviceId:' + JSON.parse(data).deviceId,
        'lang:' + JSON.parse(data).lang,
        'version:' + JSON.parse(data).version,
        'os:' + JSON.parse(data).os,
        'model:' + JSON.parse(data).model,
        'fid:' + JSON.parse(data).fid,
        'uid:' + JSON.parse(data).userId
      ]
      this.token = JSON.parse(data).token
      this.uid = JSON.parse(data).userId
      let langs = this.userInfo.lang.substring(0, 2)
      let lang = this.userInfo.lang

      

      if (langs == "zh") {
          if (lang == "zh_TW" || lang == "zh_HK") {
          this.$i18n.locale = "tc";
          } else {
          this.$i18n.locale = "zh";
          }
      } else if (langs == "en") {
          this.$i18n.locale = "en";
      } else if (langs == "tr") {
          this.$i18n.locale = "tr";
      } else if (langs == "es") {
          this.$i18n.locale = "es";
      } else if (langs == "pt") {
          this.$i18n.locale = "pt";
      } else {
          this.$i18n.locale = "en";
      }
      document.querySelector("html").setAttribute("lang", this.$i18n.locale);
      let personLeveldatas = {
        option: "get",
        host:
          this.$serviceIp +
          "api/activity/carnival/bStopTime?token=" +
          this.token +
          "&uid=" +
          this.uid,
        data: "",
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(personLeveldatas).then((data) => {
        if (data.code == 200) {
          this.countdown(data.data.stopTime)
        }else {
          Toast(data.message || this.$t('failed'))
        }
      });
      this.getRankList();
    },
  },
};
</script>

<style scoped>
@import './anchorActive.css';
</style>
